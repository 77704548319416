import { createSlice, nanoid } from '@reduxjs/toolkit';
const initialState = [];
const slice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {
    addAlert: (state, action) => {
      // modify the draft state and return nothing
      // state.push(action.payload);
      return [...state, action.payload];
    },
    removeAlert: (state, action) => {
      // replace the entire slice state
      return state.filter((alert) => alert.id !== action.payload);
    }
  }
});
const { addAlert, removeAlert } = slice.actions;
export default slice.reducer;
export const setAlert =
  (msg, alertType, timeout = 5000) =>
  (dispatch) => {
    const id = nanoid();
    dispatch(addAlert({ msg, alertType, id }));
    setTimeout(() => {
      dispatch(removeAlert(id));
    }, timeout);
  };
