import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../pages/Reports/utils';
import {
  ALL_STATUS,
  baseURL,
  REPORT_RESULT_STATUS,
  SCHEDULED_STATUS
} from '../../utils/shared/constants';
import apiClient from '../axios';

export const fetchReportData = createAsyncThunk(
  'report/fetchReportData',
  async (activeReportStatus) => {
    let endpoint;

    switch (activeReportStatus) {
      case ALL_STATUS:
        endpoint = ENDPOINTS.GET_ALL_REPORT;
        break;
      case SCHEDULED_STATUS:
      case REPORT_RESULT_STATUS:
        endpoint = `${ENDPOINTS.GET_REPORT_RESULT}?Is_Scheduled=${
          activeReportStatus === SCHEDULED_STATUS
        }`;
        break;
      default:
        throw new Error('Invalid report status');
    }

    const { data } = await apiClient.get(endpoint);
    return data;
  }
);

export const fetchAllReportData = createAsyncThunk('report/fetchAllReportData', async () => {
  const { data } = await apiClient.get(ENDPOINTS.GET_ALL_REPORT);
  return data;
});

export const fetchReportCount = createAsyncThunk('report/fetchReportCount', async () => {
  const { data } = await apiClient.get(ENDPOINTS.REPORT_COUNT_TILE);
  return data;
});

export const getDataByUrl = async (endPointUrl) => {
  const { data } = await apiClient.get(`${baseURL}${endPointUrl}`);
  return data?.paymentType || data;
};

export const saveFilterParameter = createAsyncThunk(
  'report/saveFilterParameter',
  async (formattedData, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.SAVE_FILTER_PARAMETERS, formattedData);
      return { data };
    } catch (error) {
      return rejectWithValue(error?.response?.data); // This will reject the promise with an error message
    }
  }
);

export const fetchFilterParamByUser = createAsyncThunk(
  'report/fetchFilterParamByUser',
  async (userSelectedId) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.GET_FILTER_PARAMETERS_BY_USER}?userSelectedId=${userSelectedId}`
    );
    return data;
  }
);

export const fetchAllVendors = createAsyncThunk('reports/fetchAllVendors', async () => {
  const { data } = await apiClient.get(ENDPOINTS.GET_ALL_VENDORS);
  return data;
});
