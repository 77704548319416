import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarOpen: true,
  headerText: 'Dashboard',
  tabReset: 0,
  confirmMenuText: ''
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setSidebarOpen: (state, action) => {
      return { ...state, sidebarOpen: action.payload };
    },
    setHeaderText: (state, action) => {
      return { ...state, headerText: action.payload };
    },
    setConfirmMenuText: (state, action) => {
      return { ...state, confirmMenuText: action.payload };
    },
    resetCustomTab: (state) => {
      return { ...state, tabReset: state?.tabReset + 1 };
    }
  }
});

const { reducer, actions } = mainSlice;

export const { setSidebarOpen, setHeaderText, setConfirmMenuText, resetCustomTab } = actions;

export default reducer;
