import { createSlice } from '@reduxjs/toolkit';
import { renameDocument } from '../api/services/document.service';

const initialState = {
  document: null,
  isPending: false,
  isRenamedSuccess: false,
  isError: false,
  errorMessage: null
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    setViewDocument: (state, action) => {
      return { ...state, document: action.payload };
    },
    clearViewDocument: (state) => {
      return { ...state, document: null };
    }
  },
  extraReducers(builder) {
    builder

      .addCase(renameDocument.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isRenamedSuccess: false
        };
      })
      .addCase(renameDocument.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isRenamedSuccess: true
        };
      })
      .addCase(renameDocument.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          isRenamedSuccess: false,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = documentSlice;

export const { setViewDocument, clearViewDocument } = actions;

export default reducer;
