import { getDataByUrl } from '../../api/services/report.service';
import { HAS_EMAIL_SENT, SCHEDULED_TYPE, UPLOAD_SFTP } from '../../utils/shared/constants';

export const updatedReportsData = async (reportsJsonData) => {
  const updatedSections = await Promise.all(reportsJsonData.map(updateSection));
  return updatedSections;
};

const updateSection = async (section) => {
  const updatedFields = await Promise.all(section.fields.map(updateField));
  return { ...section, fields: updatedFields };
};

const updateField = async (field) => {
  if (!(field.api && field.on_Load)) {
    if (field?.is_Multiselect && field?.field_Value?.length > 0 && field?.options?.length > 0) {
      const matchedDefaultValue = field?.options
        ?.filter((option) => field?.field_Value?.includes(option[field.id_Column_Name]))
        ?.map((matchedOption) => ({
          id: matchedOption[field.id_Column_Name],
          description: matchedOption?.description
        }));
      return {
        ...field,
        default_Value: field?.default_Value === 'false' ? false : field?.default_Value,
        field_Value: matchedDefaultValue
      };
    }
    return {
      ...field,
      default_Value: field?.default_Value === 'false' ? false : field?.default_Value
    };
  }
  const data = await getDataByUrl(field.api);
  if (!data) return field;

  const filteredData = filterData(field, data);

  return {
    ...field,
    options: data,
    field_Value: filteredData
  };
};

const filterData = (field, data) => {
  return data.filter((d) => {
    return field?.field_Value?.find((element) => element === d[field.id_Column_Name]);
  });
};

export const setFormValue = (
  fData,
  setValue,
  setCronExpression,
  convertRange,
  setSelectedDateRange
) => {
  const sType = fData?.has_email_sent ? HAS_EMAIL_SENT : UPLOAD_SFTP;
  setValue('report_id', fData?.report_ID);
  setValue('user_Name', fData?.user_Name);
  setValue('user_Password', fData?.user_Password || '');
  setValue('sftp_URL', fData?.sftp_URL);
  setValue('sftp_Folder', fData?.sftp_folder);
  setCronExpression(fData?.cron_expression);
  setValue(
    'schedule_type',
    SCHEDULED_TYPE?.find((d) => d.id == sType)
  );
  setSelectedDateRange(convertRange(fData?.scheduled_Start_Date, fData?.scheduled_End_Date));
  setValue('scheduleDate', convertRange(fData?.scheduled_Start_Date, fData?.scheduled_End_Date));
};

export const ENDPOINTS = {
  // Reports
  GET_ALL_REPORT: 'userservice/api/Report/GetAllReports',
  GET_REPORT_RESULT: 'userservice/api/Report/GetReportResults',
  REPORT_COUNT_TILE: 'userservice/api/Report/ReportCountTiles',
  SAVE_FILTER_PARAMETERS: 'userservice/api/Report/SaveReportFiltersParameters',
  GET_FILTER_PARAMETERS_BY_USER: 'userservice/api/Report/GetReportFilterByUserId',
  GET_ALL_VENDORS: 'userservice/api/Report/GetAllVendors'
};
