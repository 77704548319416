import { createSlice } from '@reduxjs/toolkit';
import {
  GetMigratedDocument,
  addOrEditPatient,
  checkPatientEligibility,
  fetchAuditLogs,
  fetchPatientCounts,
  fetchPatientDocument,
  fetchPatientList,
  fetchReturnItem,
  fetchReturnItemToStock,
  fetchReturnReason,
  getPatient,
  getState,
  removeDocument,
  searchPayerNameOrCode,
  sendEmail,
  uploadDocument,
  validateMRN
} from '../api/services/patient.service';
import { calculateAge } from '../utils/shared/common';
import { TENANT_ID } from '../utils/shared/constants';
import { formatDate } from '../utils/shared/formatHelper';

const initialState = {
  isPending: false,
  isLoadedTiles: true,
  isValidateMRN: false,
  isSuccess: false,
  isCreateEditSuccess: false,
  isDocumentUploaded: false,
  isDocumentDeleted: false,
  isDocumentDeletedPending: false,
  isUpdateSuccess: false,
  isError: false,
  emailSend: null,
  errorMessage: null,
  createdPatientData: null,
  fetchedPatientData: null,
  getMigratedDocument: null,
  fetchedSinglePatientData: null,
  fetchedState: null,
  searchedPayerNameOrCode: [],
  fetchedAuditLogs: [],
  isPatientFetched: false,
  patientListAPIPayload: {
    tenant_Id: TENANT_ID,
    globalSearch: '',
    fromDate: null,
    toDate: null,
    isScheduled: null
  },
  patientCountData: null,
  patientList: [],
  patientDialogMode: null,
  isDocumentUploadPending: false,
  isDocumentNameEdited: false,
  isPatientEditSuccess: false,
  isStartReturnModalOpen: false,
  visitSummaryDialog: { isDiagShow: false, visitId: null },
  duplicatePatient: {
    isDiagShow: false,
    isPatientRowClicked: false,
    clickedPatientData: {},
    duplicateData: []
  },
  returnReason: [],
  returnItemData: [],
  returnItemStock: []
};

const patientSlice = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setSelectedPatientData: (state, action) => {
      return { ...state, fetchedPatientData: action.payload };
    },
    setSearchedPayerData: (state, action) => {
      return { ...state, searchedPayerNameOrCode: action.payload };
    },
    setPatientLists: (state, action) => {
      return { ...state, patientList: action.payload };
    },
    setPatientListAPIPayload: (state, action) => {
      return { ...state, patientListAPIPayload: action.payload };
    },
    setDocumentUpload: (state, action) => {
      return { ...state, isDocumentUploaded: action.payload };
    },
    setPatientDialogMode: (state, action) => {
      return { ...state, patientDialogMode: action.payload };
    },
    clearPatientSingleData: (state) => {
      return { ...state, fetchedPatientData: [], fetchedSinglePatientData: [] };
    },
    setDocumentNameEdited: (state, action) => {
      return { ...state, isDocumentNameEdited: action.payload };
    },
    setPatientEdited: (state, action) => {
      return { ...state, isPatientEditSuccess: action.payload };
    },
    setRemoveDocument: (state, action) => {
      return { ...state, isDocumentDeleted: action.payload };
    },
    setIsCreateEditSuccess: (state, action) => {
      return { ...state, isCreateEditSuccess: action.payload };
    },
    setIsStartReturnModalOpen: (state, action) => {
      return { ...state, isStartReturnModalOpen: action.payload };
    },
    setEmailSend: (state, action) => {
      return { ...state, emailSend: action.payload };
    },
    setReurnItemData: (state, action) => {
      return { ...state, returnItemData: action.payload };
    },

    setVisitSummaryDialog: (state, action) => {
      return {
        ...state,
        visitSummaryDialog: {
          isDiagShow: action.payload.isDiagShow,
          visitId: action.payload.visitId
        }
      };
    },
    setDuplicatePatientDialog: (state, action) => {
      return {
        ...state,
        duplicatePatient: {
          ...state.duplicatePatient,
          isPatientRowClicked: action.payload.isPatientRowClicked || false,
          clickedPatientData: action.payload.clickedPatientData || {},
          duplicateData: action.payload.duplicateData || [],
          isDiagShow: action.payload.isDiagShow || false
        }
      };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(addOrEditPatient.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isPatientEditSuccess: false
        };
      })
      .addCase(addOrEditPatient.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isCreateEditSuccess: action?.payload?.mode === 'add',
          createdPatientData: action.payload,
          isPatientEditSuccess: true
        };
      })
      .addCase(addOrEditPatient.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          isPatientEditSuccess: false
        };
      })
      .addCase(uploadDocument.pending, (state) => {
        return {
          ...state,
          isDocumentUploadPending: true,
          isDocumentUploaded: false,
          isPatientEditSuccess: false
        };
      })
      .addCase(uploadDocument.fulfilled, (state) => {
        return {
          ...state,
          isDocumentUploadPending: false,
          isDocumentUploaded: true,
          isDocumentNameEdited: false
        };
      })
      .addCase(uploadDocument.rejected, (state, action) => {
        return {
          ...state,
          isDocumentUploadPending: false,
          isDocumentUploaded: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(removeDocument.pending, (state) => {
        return {
          ...state,
          isPending: true,
          isDocumentDeleted: false,
          isDocumentDeletedPending: true
        };
      })
      .addCase(removeDocument.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isDocumentDeleted: true,
          isDocumentDeletedPending: false
        };
      })
      .addCase(removeDocument.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isDocumentDeleted: false,
          isError: true,
          errorMessage: action.error.message,
          isDocumentDeletedPending: false
        };
      })
      .addCase(sendEmail.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          emailSend: action.payload
        };
      })
      .addCase(sendEmail.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(validateMRN.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(validateMRN.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          isValidateMRN: action.payload
        };
      })
      .addCase(validateMRN.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchAuditLogs.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchAuditLogs.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          fetchedAuditLogs: action.payload
        };
      })
      .addCase(fetchAuditLogs.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(getState.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(getState.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          fetchedState: action.payload
        };
      })
      .addCase(getState.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(getPatient.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(getPatient.fulfilled, (state, action) => {
        const list = action.payload.data;

        let fetchedData = [];

        const newData = {
          ...list,
          payer_level: list?.patientPayerDetails?.[0]?.payer_First_name,
          policy_number: list?.patientPayerDetails?.[0]?.subscriber_Member_Id,
          patientName: `${list.first_Name} ${list.last_Name}`,
          date_Of_Birth: formatDate(list.date_Of_Birth),
          age: calculateAge(list.date_Of_Birth),
          hL7: false
        };

        fetchedData = newData;

        return {
          ...state,
          isPending: false,
          isSuccess: true,
          isUpdateSuccess: false,
          fetchedPatientData: fetchedData,
          fetchedSinglePatientData: list
        };
      })
      .addCase(getPatient.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(searchPayerNameOrCode.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(searchPayerNameOrCode.fulfilled, (state, action) => {
        const searchedPayerNameOrCode = action?.payload?.items;

        return {
          ...state,
          isPending: false,
          isSuccess: true,
          searchedPayerNameOrCode
        };
      })
      .addCase(searchPayerNameOrCode.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchPatientCounts.pending, (state) => {
        return {
          ...state,
          isLoadedTiles: true
        };
      })
      .addCase(fetchPatientCounts.fulfilled, (state, action) => {
        const patientCountData = action.payload
          .filter(
            (item) => !(item?.description === 'Scheduled Patients' && item?.patient_Count === 0)
          )
          .map((item) => {
            return {
              ...item,
              id: item.description === 'Scheduled Patients' ? 'scheduled' : 'manual'
            };
          });
        return {
          ...state,
          isLoadedTiles: false,
          isSuccess: true,
          patientCountData
        };
      })
      .addCase(fetchPatientCounts.rejected, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchPatientList.pending, (state) => {
        return {
          ...state,
          isPatientFetched: true
        };
      })
      .addCase(fetchPatientList.fulfilled, (state, action) => {
        let fetchedPatientListData = [];

        if (action?.payload) {
          const newData = action?.payload?.map((p) => {
            const payerDetails = p.patientPayerDetails?.[0]?.tenantPayers;

            p.patientName = `${p.first_Name} ${p.last_Name}`;
            p.date_Of_Birth = formatDate(p.date_Of_Birth);
            p.age = calculateAge(p.date_Of_Birth);
            p.payer_level = payerDetails?.payer_level ?? null;
            p.policy_number = payerDetails?.policy_number ?? null;

            return p;
          });

          fetchedPatientListData = newData;
        }

        return {
          ...state,
          isPatientFetched: false,
          isSuccess: true,
          patientList: fetchedPatientListData
        };
      })
      .addCase(fetchPatientList.rejected, (state, action) => {
        return {
          ...state,
          isPatientFetched: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(checkPatientEligibility.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(checkPatientEligibility.fulfilled, (state, action) => {
        const eligibilityData = action.payload;
        const updatedFetchedPatientData = {
          ...state.fetchedPatientData,
          patientEligibilities: eligibilityData
        };
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          fetchedPatientData: updatedFetchedPatientData,
          eligibilityData: eligibilityData
        };
      })
      .addCase(checkPatientEligibility.rejected, (state) => {
        return {
          ...state,
          isPending: false,
          isError: true
        };
      })
      .addCase(fetchPatientDocument.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchPatientDocument.fulfilled, (state, action) => {
        const updatedFetchedPatientData = {
          ...state.fetchedPatientData,
          patientDocuments: action.payload
        };
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          fetchedPatientData: updatedFetchedPatientData
        };
      })
      .addCase(fetchPatientDocument.rejected, (state) => {
        return {
          ...state,
          isPending: false,
          isError: true
        };
      })
      .addCase(GetMigratedDocument.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(GetMigratedDocument.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          getMigratedDocument: action.payload
        };
      })
      .addCase(GetMigratedDocument.rejected, (state) => {
        return {
          ...state,
          isPending: false,
          isError: true
        };
      })
      .addCase(fetchReturnReason.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchReturnReason.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          returnReason: action.payload
        };
      })
      .addCase(fetchReturnReason.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchReturnItem.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchReturnItem.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          returnItemData: action.payload
        };
      })
      .addCase(fetchReturnItem.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchReturnItemToStock.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchReturnItemToStock.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          returnItemStock: action.payload
        };
      })
      .addCase(fetchReturnItemToStock.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = patientSlice;

export const {
  setSelectedPatientData,
  setSearchedPayerData,
  setPatientLists,
  setPatientListAPIPayload,
  setDocumentUpload,
  setPatientDialogMode,
  clearPatientSingleData,
  setDocumentNameEdited,
  setPatientEdited,
  setRemoveDocument,
  setEmailSend,
  setVisitSummaryDialog,
  setDuplicatePatientDialog,
  setIsCreateEditSuccess,
  setReurnItemData,
  setIsStartReturnModalOpen
} = actions;

export default reducer;
