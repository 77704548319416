import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { getToken } from '../api/auth';
import { getTermsAndConditions } from '../api/services/termsCondition.js';
import { fetchUserTenant, fetchUserTenantLocation } from '../api/services/user.service.js';
import Loader from '../components/Loader';
import ProtectedRoute from '../components/ProtectedRoute';
import RefreshToken from '../components/RefreshToken';
import ToastContainer from '../components/ToastContainer';
import Error403 from '../pages/Error403/index.js';
import { lazyWithRetry } from '../utils/lazyWithRetry.js';

const ErrorPage = lazy(() => lazyWithRetry(() => import('../pages/ErrorPage'), 'Error404'));
const UserEmailValidation = lazy(() =>
  lazyWithRetry(() => import('../pages/UserEmailValidation'), 'UserEmailValidation')
);
const Modal = lazy(() => lazyWithRetry(() => import('../pages/ConditionalModal'), 'Modal'));
const Dashboard = lazy(() =>
  lazyWithRetry(() => import('../pages/Dashboard/Dashboard'), 'Dashboard')
);
const Maintenance = lazy(() =>
  lazyWithRetry(() => import('../pages/Maintenance/Maintenance'), 'Maintenance')
);
const Patients = lazy(() => lazyWithRetry(() => import('../pages/Patients'), 'Patients'));
const Purchase = lazy(() => lazyWithRetry(() => import('../pages/Purchase'), 'Purchase'));
const Reports = lazy(() => lazyWithRetry(() => import('../pages/Reports'), 'Reports'));
const Visits = lazy(() => lazyWithRetry(() => import('../pages/Visits'), 'Visits'));
const NewVisit = lazy(() => lazyWithRetry(() => import('../pages/Visits/NewVisit'), 'NewVisit'));
const Stock = lazy(() => lazyWithRetry(() => import('../pages/Stock/Stock'), 'Stock'));
const Catalog = lazy(() => lazyWithRetry(() => import('../pages/Catalog'), 'Catalog'));
const Settings = lazy(() => lazyWithRetry(() => import('../pages/Settings'), 'Settings'));
const MiniDrawer = lazy(() =>
  lazyWithRetry(() => import('../layout/SliderBar/SliderBar'), 'MiniDrawer')
);
const Help = lazy(() => lazyWithRetry(() => import('../pages/Help/Help'), 'Help'));
const DocumentSignature = lazy(() =>
  lazyWithRetry(
    () => import('../pages/Visits/NewVisit/SignDocuments/DocumentSignature'),
    'DocumentSignature'
  )
);
const VisitSummary = lazy(() =>
  lazyWithRetry(() => import('../pages/Visits/NewVisit/VisitSummary/VisitSummary'), 'VisitSummary')
);

const MainRoutes = ({ isAuthenticated }) => {
  const { isOpen, apiResponse } = useSelector((state) => state.termsAndConditions);
  const [onLine, setOnLine] = useState(navigator.onLine);

  let mainContent;

  if (!onLine) {
    mainContent = <ErrorPage errorCode="noInternet" />;
  } else if (isAuthenticated) {
    mainContent = <MiniDrawer />;
  } else {
    mainContent = <UserEmailValidation />;
  }

  const { userTenant } = useSelector((state) => state.user);
  const tenantID = userTenant?.userInformation?.last_Tenant_Id;
  const sessionTenantID = localStorage.getItem('TenantID');
  const dispatch = useDispatch();
  const { accounts } = useMsal();

  useEffect(() => {
    if (!userTenant && getToken() && accounts.length > 0) {
      let userEmail = accounts[0].username
        ? accounts[0]?.username
        : accounts[0]?.idTokenClaims.email;
      dispatch(fetchUserTenant())
        .unwrap()
        .then(() => dispatch(fetchUserTenantLocation()));
      dispatch(getTermsAndConditions(userEmail));
    }
  }, []);

  useEffect(() => {
    if (tenantID && sessionTenantID !== tenantID)
      localStorage.setItem('TenantID', userTenant?.userInformation?.last_Tenant_Id);
  }, [userTenant?.userInformation?.last_Tenant_Id, sessionTenantID]);

  useEffect(() => {
    window.addEventListener('offline', () => {
      setOnLine(false);
    });
    window.addEventListener('online', () => {
      setOnLine(true);
    });
    return () => {
      window.removeEventListener('offline', () => setOnLine(false));
      window.removeEventListener('online', () => setOnLine(false));
    };
  });

  const router = createBrowserRouter([
    {
      path: '/',
      element: mainContent,
      errorElement: <ErrorPage />,
      children: [
        {
          element: isOpen && apiResponse ? <Modal /> : <ProtectedRoute />,
          children: [
            {
              index: true,
              element:
                isOpen && apiResponse ? (
                  <Modal />
                ) : (
                  <RefreshToken>
                    <Dashboard />
                  </RefreshToken>
                )
            },
            {
              path: '/dashboard',
              element: (
                <RefreshToken>
                  <Dashboard />
                </RefreshToken>
              )
            },
            {
              path: 'maintenance',
              element: (
                <RefreshToken>
                  <Maintenance />
                </RefreshToken>
              )
            },
            {
              path: 'patients',
              element: (
                <RefreshToken>
                  <Patients />
                </RefreshToken>
              )
            },
            {
              path: 'stock',
              element: (
                <RefreshToken>
                  <Stock />
                </RefreshToken>
              )
            },
            {
              path: 'purchase',
              element: (
                <RefreshToken>
                  <Purchase />
                </RefreshToken>
              )
            },
            {
              path: 'catalog',
              element: (
                <RefreshToken>
                  <Catalog />
                </RefreshToken>
              )
            },
            {
              path: 'reports',
              element: (
                <RefreshToken>
                  <Reports />
                </RefreshToken>
              )
            },
            {
              path: 'settings',
              element: (
                <RefreshToken>
                  <Settings />
                </RefreshToken>
              )
            },
            {
              path: 'visits',
              element: (
                <RefreshToken>
                  <Visits />
                </RefreshToken>
              )
            },
            {
              path: 'visit/new',
              element: (
                <RefreshToken>
                  <NewVisit />
                </RefreshToken>
              )
            },
            {
              path: ':patient_id/visit/:visit_Id/visit-summary',
              element: (
                <RefreshToken>
                  <VisitSummary />
                </RefreshToken>
              )
            },
            {
              path: ':patient_id/visit/:visit_Id',
              element: (
                <RefreshToken>
                  <NewVisit />
                </RefreshToken>
              )
            },
            {
              path: ':patient_id/visit/:visit_Id/sign-document',
              element: (
                <RefreshToken>
                  <DocumentSignature />
                </RefreshToken>
              )
            },
            {
              path: 'help',
              element: (
                <RefreshToken>
                  <Help />
                </RefreshToken>
              )
            },
            {
              path: 'unauthorized',
              element: (
                <RefreshToken>
                  <Error403 />
                </RefreshToken>
              )
            }
          ]
        }
      ]
    }
  ]);

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
      <ToastContainer />
    </Suspense>
  );
};

MainRoutes.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired
};

export default MainRoutes;
