import { createSlice } from '@reduxjs/toolkit';
import {
  fetchCustomFitDataByAI,
  fetchVisitCounts,
  fetchVisitList,
  updateItemToCart
} from '../api/services/visits.service';

const initialState = {
  isPending: false,
  isLoadedTiles: true,
  isVisitFetched: false,
  isCustomFitDataFetched: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
  openGenerateByAIModal: false,
  openAITandCModal: false,
  visitListAPIPayload: {
    globalSearchValue: '',
    from_Date: null,
    to_Date: null,
    location: null,
    provider: null,
    fitter: null,
    visit_Status_Id: null
  },
  visitCountData: null,
  cutomFitDataByAI: null,
  visitList: [],
  updateItemCartData: [],
  fromCompletedVisits: false
};

const visitsSlice = createSlice({
  name: 'visits',
  initialState,
  reducers: {
    setVisitLists: (state, action) => {
      return { ...state, visitList: action.payload };
    },
    setVisitListAPIPayload: (state, action) => {
      return { ...state, visitListAPIPayload: action.payload };
    },
    setOpenGenerateByAIModal: (state, action) => {
      return { ...state, openGenerateByAIModal: action.payload };
    },
    resetCutomFitDataByAI: (state) => {
      return { ...state, cutomFitDataByAI: null };
    },
    setOpenAITandCModal: (state, action) => {
      return { ...state, openAITandCModal: action.payload };
    },
    setFromCompletedVisits: (state, action) => {
      return { ...state, fromCompletedVisits: action.payload };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVisitCounts.pending, (state) => {
        return {
          ...state,
          isLoadedTiles: true
        };
      })
      .addCase(fetchVisitCounts.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isSuccess: true,
          visitCountData: action.payload
        };
      })
      .addCase(fetchVisitCounts.rejected, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchVisitList.pending, (state) => {
        return {
          ...state,
          isVisitFetched: true
        };
      })
      .addCase(fetchVisitList.fulfilled, (state, action) => {
        return {
          ...state,
          isVisitFetched: false,
          isSuccess: true,
          visitList: action.payload.visitSearchResponse
        };
      })
      .addCase(fetchVisitList.rejected, (state, action) => {
        return {
          ...state,
          isVisitFetched: false,
          isError: true,
          errorMessage: action.error.message
        };
      })

      .addCase(fetchCustomFitDataByAI.pending, (state) => {
        return {
          ...state,
          isCustomFitDataFetched: true
        };
      })
      .addCase(fetchCustomFitDataByAI.fulfilled, (state, action) => {
        return {
          ...state,
          isCustomFitDataFetched: false,
          isSuccess: true,
          cutomFitDataByAI: action.payload
        };
      })
      .addCase(fetchCustomFitDataByAI.rejected, (state, action) => {
        return {
          ...state,
          isCustomFitDataFetched: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(updateItemToCart.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(updateItemToCart.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          updateItemCartData: action.payload
        };
      })
      .addCase(updateItemToCart.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = visitsSlice;

export const {
  setVisitListAPIPayload,
  setVisitLists,
  setOpenGenerateByAIModal,
  resetCutomFitDataByAI,
  setOpenAITandCModal,
  setFromCompletedVisits
} = actions;

export default reducer;
