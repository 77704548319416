import { configureStore } from '@reduxjs/toolkit';
import alertsReducer from './slices/alerts.slice';
import catalogReducer from './slices/catalog.slice';
import documentReducer from './slices/document.slice';
import mainReducer from './slices/main.slice';
import newVisitReducer from './slices/newvisit.slice';
import patientReducer from './slices/patient.slice';

import purchaseReducer from './slices/purchase.slice';
import reportReducer from './slices/report.slice';
import tenantInfoReducer from './slices/tenantInfo.slice';

import settingsReducer from './slices/settings.slice';
import stockReducer from './slices/stock.slice';
import termsAndConditionsReducer from './slices/termsAndConditions.slice';
import userReducer from './slices/user.slice';
import visitMasterReducer from './slices/visitMaster.slice';
import visitsReducer from './slices/visits.slice';

const reducer = {
  visits: visitsReducer,
  newVisit: newVisitReducer,
  alerts: alertsReducer,
  main: mainReducer,
  patient: patientReducer,
  termsAndConditions: termsAndConditionsReducer,
  user: userReducer,
  purchase: purchaseReducer,
  report: reportReducer,
  document: documentReducer,
  visitMaster: visitMasterReducer,
  stock: stockReducer,
  tenantInfo: tenantInfoReducer,
  settings: settingsReducer,
  catalog: catalogReducer
};

export const store = configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
});
