import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAuthStatusDetails,
  fetchItemBillingTypes,
  fetchProviderDetails,
  fetchStatusDetails
} from '../api/services/visitMaster.service';

const initialState = {
  billingType: null,
  loadingVisitData: true,
  errorInVisitMasterData: null,
  visitStatus: null,
  visitAuthStatus: null,
  providerTypes: null
};

const visitMasterSlice = createSlice({
  name: 'visitMaster',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchItemBillingTypes.pending, (state) => {
        return {
          ...state,
          loadingVisitData: true
        };
      })
      .addCase(fetchItemBillingTypes.fulfilled, (state, action) => {
        return {
          ...state,
          loadingVisitData: false,
          billingType: action.payload.billingTypes
        };
      })
      .addCase(fetchItemBillingTypes.rejected, (state, action) => {
        return {
          ...state,
          loadingVisitData: false,
          errorInVisitMasterData: action.error.message
        };
      })

      .addCase(fetchProviderDetails.pending, (state) => {
        return {
          ...state,
          loadingVisitData: true
        };
      })
      .addCase(fetchProviderDetails.fulfilled, (state, action) => {
        const orderingProviderType = action?.payload?.['Ordering Provider Type'];
        const supervisingProviderType = action?.payload?.['Supervising Provider Type'];
        const renderingProviderType = action?.payload?.['Rendering Provider Type'];
        const fitterProviderType = action?.payload?.['Fitter Provider Type'];

        const providerTypes = {
          orderingProviderType,
          supervisingProviderType,
          renderingProviderType,
          fitterProviderType
        };

        return {
          ...state,
          loadingVisitData: false,
          providerTypes
        };
      })
      .addCase(fetchProviderDetails.rejected, (state, action) => {
        return {
          ...state,
          loadingVisitData: false,
          errorInVisitMasterData: action.error.message
        };
      })

      .addCase(fetchStatusDetails.pending, (state) => {
        return {
          ...state,
          loadingVisitData: true
        };
      })
      .addCase(fetchStatusDetails.fulfilled, (state, action) => {
        return {
          ...state,
          loadingVisitData: false,
          visitStatus: action.payload.data
        };
      })
      .addCase(fetchStatusDetails.rejected, (state, action) => {
        return {
          ...state,
          loadingVisitData: false,
          errorInVisitMasterData: action.error.message
        };
      })

      .addCase(fetchAuthStatusDetails.pending, (state) => {
        return {
          ...state,
          loadingVisitData: true
        };
      })
      .addCase(fetchAuthStatusDetails.fulfilled, (state, action) => {
        return {
          ...state,
          loadingVisitData: false,
          visitAuthStatus: action.payload.data
        };
      })
      .addCase(fetchAuthStatusDetails.rejected, (state, action) => {
        return {
          ...state,
          loadingVisitData: false,
          errorInVisitMasterData: action.error.message
        };
      });
  }
});

const { reducer } = visitMasterSlice;

export default reducer;
