import { CircularProgress } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';

const Loader = () => {
  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={true}
      data-testid="backdrop">
      <CircularProgress color="inherit" data-testid="circular-progress" />
    </Backdrop>
  );
};

export default Loader;
