import { t } from 'i18next';
import { compact, filter, isObject, map, mapKeys } from 'lodash';
import moment from 'moment';
import { EMPTY_DATE, IPADDRESS_FETCH } from './constants';

export const getErrorMessage = (errors, fieldName, fieldsToCheck, isFieldArray = false) => {
  if (!isFieldArray) {
    const errorType =
      errors && ['required', 'manual', 'pattern', 'validate'].includes(errors[fieldName]?.type);
    return errorType ? errors[fieldName]?.message : '';
  } else {
    const fieldParts = fieldName.split('.');
    const currentName = fieldParts[2];

    const foundError = fieldsToCheck?.reduce((acc, field) => {
      const items = compact(errors[field]) || [];

      const matchingItem = items?.find((item) => {
        return item[currentName]?.ref?.name === fieldName;
      });

      return acc || matchingItem;
    }, null);

    return foundError ? foundError[currentName].message : '';
  }
};

export const getClientIP = async () => {
  const storedIpAddress = localStorage.getItem('ipaddress');
  if (storedIpAddress) {
    return storedIpAddress;
  } else {
    try {
      const response = await fetch(IPADDRESS_FETCH);
      const ipAddress = await response.text();
      localStorage.setItem('ipaddress', ipAddress);
      return ipAddress;
    } catch (error) {
      console.error('Error fetching IP address:', error);
      throw error;
    }
  }
};

export const newSinglePatientDataObj = (fetchedSinglePatientData) => {
  return {
    firstName: fetchedSinglePatientData?.first_Name,
    lastName: fetchedSinglePatientData?.last_Name,
    dob: moment(fetchedSinglePatientData?.date_Of_Birth).format('MM/DD/YYYY'),
    MRN: fetchedSinglePatientData?.account_Id,
    email: fetchedSinglePatientData?.primary_Email,
    mobileNo: fetchedSinglePatientData?.primary_Phone,
    phoneNo: fetchedSinglePatientData?.secondary_Phone,
    gender: fetchedSinglePatientData?.gender,
    addressLine1: fetchedSinglePatientData?.address_Line1,
    addressLine2: fetchedSinglePatientData?.address_Line2,
    city: fetchedSinglePatientData?.city,
    state: fetchedSinglePatientData?.state_Province,
    zipCode: fetchedSinglePatientData?.zip_Code,
    documents: fetchedSinglePatientData?.patientDocuments.map((doc) => {
      return {
        doc: {
          path: doc.document_Blob_Url,
          name: doc.document_Name,
          id: doc.id
        },
        documentlist: [
          {
            DocumentName: `${doc.id}##${doc.document_Name}##${doc.document_Blob_Url}`
          }
        ],
        isFetched: true
      };
    }),
    payerDetails: fetchedSinglePatientData?.patientPayerDetails.map((payer) => {
      return {
        group: payer?.group_Number,
        isDeletable: false,
        isPatient: payer?.subscriber_Relationship_To_Patient === 'Patient',
        payer_id: payer?.id,
        payerlevel: payer?.payer_level,
        payernameandcode: {
          id: payer?.tenant_Payer_Id,
          payer_id: payer?.code_Id,
          payer_name: payer?.payer_First_name
        },
        show: true,
        subDOB:
          payer?.subscriber_Dob === EMPTY_DATE
            ? null
            : moment(payer.subscriber_Dob).format('MM/DD/YYYY'),
        subFirstName: payer?.subscriber_First_Name,
        subLastName: payer?.subscriber_Last_Name,
        subMemberID: payer?.subscriber_Member_Id,
        SubRelationship: payer?.subscriber_Relationship_To_Patient
      };
    })
  };
};

export const scrollToBottomOnDrop = (id) => {
  const topElement = document.getElementById(id);
  if (topElement) {
    const dialogContent = topElement.querySelector('.MuiDialog-paperScrollPaper');
    if (dialogContent) {
      dialogContent.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'smooth'
      });
    }
  }
};

export const calPercentage = (total, subTotal) => {
  if (!total || total === 0 || !subTotal) {
    return '0';
  }
  return ((subTotal / total) * 100).toFixed(2);
};

export const validateFutureDate = (selectedDate) => {
  const currentDate = moment();
  const selectedMoment = moment(selectedDate);
  if (!selectedMoment.isValid() && selectedDate) {
    return t('addPatient.validationMessage.inValidDate');
  }
  if (selectedMoment.isAfter(currentDate)) {
    return t('addPatient.validationMessage.inValidDOB');
  }
  return undefined;
};

export const validateDate = (selectedDate, validationType) => {
  const currentDate = moment().startOf('day');
  const selectedMoment = moment(selectedDate, 'YYYY-MM-DD').startOf('day'); // Adjust date format as needed

  if (!selectedMoment.isValid() && selectedDate) {
    return t('addPatient.validationMessage.inValidDate');
  }

  if (validationType === 'future' && selectedMoment.isAfter(currentDate)) {
    return t('addPatient.validationMessage.inValidDOB');
  }

  if (validationType === 'past' && selectedMoment.isBefore(currentDate)) {
    return t('addPatient.validationMessage.inValidPastDate');
  }

  return undefined;
};

export const getDatesInMonth = (year, month) => {
  const numDays = new Date(year, month + 1, 0).getDate();
  const dates = [];
  for (let day = 1; day <= numDays; day++) {
    dates.push(day);
  }
  return dates;
};

export const weekdaysArr = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const scheduleList = ['Daily', 'Weekly', 'Monthly'];
export const updateIndex = (arr) => arr?.map((el, i) => ({ ...el, rIndex: i })) || [];

export const calculateAge = (dateOfBirth) => {
  if (!dateOfBirth || dateOfBirth?.trim() === '0001-01-01') {
    return null;
  }

  const [year, month, day] = dateOfBirth.split('-').map(Number);

  const today = new Date();
  const todayYear = today.getFullYear();
  const todayMonth = today.getMonth() + 1; // getMonth() is zero-based
  const todayDay = today.getDate();

  let age = todayYear - year;

  if (
    parseInt(todayMonth) < parseInt(month) ||
    (parseInt(todayMonth) === parseInt(month) && parseInt(todayDay) < parseInt(day))
  ) {
    age--;
  }

  if (age < 0) {
    age = 0;
  }

  return age;
};

export const downloadFile = (url) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('target', '_blank');
  link.setAttribute('download', '');
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadTemplateFile = (url, filename) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('target', '_blank');
  link.setAttribute('download', filename);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const DuplicatePayerCheck = (watchPayerDetails) => {
  if (watchPayerDetails?.length > 0) {
    return watchPayerDetails?.slice(0, -1).some((prevRecord) => {
      const currentRecord = watchPayerDetails[watchPayerDetails?.length - 1];
      return (
        currentRecord?.payernameandcode?.payer_id === prevRecord?.payernameandcode?.payer_id &&
        currentRecord?.subMemberID === prevRecord?.subMemberID
      );
    });
  }
};

export const validateDateRange = (selectedDate) => {
  const start = moment(selectedDate[0]);
  const end = moment(selectedDate[1]);
  if (!end.isValid() || !start.isValid() || start.isAfter(end)) {
    return t('addPatient.validationMessage.inValidDate');
  }
  return undefined;
};

export const updateApiCodeLoadingInVisitItem = (payload, timerData, isTimely = false) => {
  const list = payload || {};

  const lowerCaseVisitItems = map(list?.visitItems, (obj) =>
    mapKeys(obj, (_, key) => key.toLowerCase())
  );

  const dataToMatch = isTimely ? timerData : list?.soSResponses;

  const visitItems = lowerCaseVisitItems.map((item) => {
    let matchingItem = dataToMatch.find((elem) => elem.hcpcs === item.hcpcs);

    return {
      ...item,
      apiCodeLoading: matchingItem ? matchingItem?.apiResponseCode !== 3 : false
    };
  });

  const visitData = { ...list, visitItems };

  return visitData;
};

export const extractFileName = (url) => {
  try {
    // Create a URL object
    const urlObj = new URL(url);
    // Extract the pathname
    const pathname = urlObj.pathname;
    // Split the pathname by '/' and get the last part
    const fileName = pathname.split('/').pop();
    // Decode the file name
    const decodedFileName = decodeURIComponent(fileName);
    return decodedFileName;
  } catch (error) {
    console.error('Invalid URL:', error);
    return null;
  }
};

export const filteredGridData = (gridRef, gridApi) => {
  if (!gridApi) return [];
  const data = gridRef?.current?.getModel().rowsToDisplay.map((node) => node.data) || [];
  return compact(filter(data, isObject));
};
