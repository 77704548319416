export default function componentStyleOverrides(theme) {
  const bgColor = theme.colors?.grey50;
  return {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: '#fff'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: '0',
          minWidth: 'auto',

          ...(ownerState.color === 'primary' && {
            background: '#FFC758',
            color: '#151C22',
            padding: '4px 30px',
            border: '1px solid #FFC758',
            borderRadius: '5px',
            '&:hover': {
              background: '#FFC758',
              color: '#151C22',
              padding: '4px 30px',
              border: '1px solid #FFC758',
              boxShadow: '0 0 0'
            },
            '&:disabled': {
              background: '#86729F',
              border: '1px solid #86729F',
              color: '#ffffff',
              opacity: '0.5'
            }
          }),
          //primary btn

          ...(ownerState.color === 'secondary' && {
            color: '#111B2A',
            borderColor: '#7100FB',
            padding: '4px 30px',

            '&:hover': {
              boxShadow: '0 0 0',
              color: '#7100FB',
              borderColor: '#7100FB'
            },
            '&:disabled': {
              background: '#111B2A',
              color: '#ffffff',
              border: '1px solid #111B2A',
              opacity: '0.2'
            }
          }),
          //secondary btn

          ...(ownerState.color === 'negative' && {
            background: '#ffffff',
            color: '#4F596D',
            border: '1px solid transparent',
            '&:hover': {
              boxShadow: '0 0 0',
              background: 'transparent',
              color: '#2B83FF',
              border: '1px solid transparent',
              textDecoration: 'underline'
            },
            '&:disabled': {
              background: 'transparent',
              color: '#2B83FF',
              border: '1px solid transparent',
              opacity: '0.5'
            }
          }),
          //negative btn

          ...(ownerState.variant === 'text' && {
            color: '#529DE8',
            background: 'transparent',
            border: '1px solid transparent',
            padding: '4px 8px',
            '&:hover': {
              background: 'transparent'
            }
          }),
          //string btn

          borderRadius: '5px',
          fontSize: '1rem',
          fontWeight: 500,
          height: 'auto',
          lineHeight: '1.75',
          textTransform: 'none',
          boxShadow: '0 0 0',
          outline: '0',
          '&:first-of-type': {
            marginLeft: '0'
          }
        })
      }
    },
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#ffffff',
          color: '#505050',
          fontSize: '0.875rem'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          background: '#122535',
          color: '#ffffff',
          width: '100%',
          height: '100%'
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: 16
        },
        gutterTop: {
          marginTop: 16
        }
      }
    },

    MuiPaper: {
      defaultProps: {
        elevation: 0
      },
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        },
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`
        }
      }
    },
    MuiCardHeader: {
      styleOverrides: {
        root: {
          color: theme.colors?.textDark,
          padding: '24px'
        },
        title: {
          fontSize: '1.125rem'
        }
      }
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: '24px'
        }
      }
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: '10px',
          paddingBottom: '10px',
          '&.Mui-selected': {
            color: theme.menuSelected,
            backgroundColor: theme.menuSelectedBack,
            '&:hover': {
              backgroundColor: theme.menuSelectedBack
            },
            '& .MuiListItemIcon-root': {
              color: theme.menuSelected
            }
          },
          '&:hover': {
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            '& .MuiListItemIcon-root': {
              color: theme.menuSelected
            }
          }
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: '36px'
        }
      }
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: theme.textDark
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          '&::placeholder': {
            color: theme.darkTextSecondary,
            fontSize: '0.875rem'
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: bgColor,
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.colors?.grey400
          },
          '&:hover $notchedOutline': {
            borderColor: theme.colors?.primaryLight
          },
          '&.MuiInputBase-multiline': {
            padding: 1
          }
        },
        input: {
          fontWeight: 500,
          background: bgColor,
          padding: '15.5px 14px',
          borderRadius: `${theme?.customization?.borderRadius}px`,
          '&.MuiInputBase-inputSizeSmall': {
            padding: '10px 14px',
            '&.MuiInputBase-inputAdornedStart': {
              paddingLeft: 0
            }
          }
        },
        inputAdornedStart: {
          paddingLeft: 4
        },
        notchedOutline: {
          borderRadius: `${theme?.customization?.borderRadius}px`
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: theme.colors?.grey300
          }
        },
        mark: {
          backgroundColor: theme.paper,
          width: '4px'
        },
        valueLabel: {
          color: theme?.colors?.primaryLight
        }
      }
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: theme.divider,
          opacity: 1
        }
      }
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-deletable .MuiChip-deleteIcon': {
            color: 'inherit'
          }
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          color: theme.paper,
          background: theme.colors?.grey700
        }
      }
    }
  };
}
