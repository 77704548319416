import { createSlice } from '@reduxjs/toolkit';
import {
  fetchAllReportData,
  fetchAllVendors,
  fetchFilterParamByUser,
  fetchReportCount,
  fetchReportData,
  saveFilterParameter
} from '../api/services/report.service';
import { fetchProviderDetails } from '../api/services/visitMaster.service';
import { REPORT_RESULT_STATUS } from '../utils/shared/constants';

const initialState = {
  isPending: false,
  isLoadedTiles: true,
  isUpdateLoad: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
  reportData: [],
  fieldData: [],
  reportCount: [],
  allVendors: [],
  reportParameterModal: {
    rData: null,
    isView: false,
    isOpen: false
  },
  scheduleReportModal: {
    isOpen: false,
    isAdd: false,
    isEdit: false,
    rData: false
  },
  generatedReportModal: {
    isOpen: false
  },
  actveReportStatus: REPORT_RESULT_STATUS,
  allReportDataDropDown: [],
  scheduleUserData: []
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setActveReportStatus: (state, action) => {
      return { ...state, actveReportStatus: action.payload };
    },
    setReportParameterModal: (state, action) => {
      return { ...state, reportParameterModal: action.payload };
    },
    setScheduleReportModalData: (state, action) => {
      let rt = { ...state.scheduleReportModal, rData: action.payload };
      return { ...state, scheduleReportModal: rt };
    },
    setPendingState: (state, action) => {
      return { ...state, isPending: action.payload };
    },
    setScheduleReportModal: (state, action) => {
      return { ...state, scheduleReportModal: action.payload };
    },
    setFieldData: (state, action) => {
      return { ...state, fieldData: action.payload };
    },
    setGeneratedReportModal: (state, action) => {
      return {
        ...state,
        generatedReportModal: action.payload,
        actveReportStatus: !action.payload?.isOpen
          ? REPORT_RESULT_STATUS
          : state?.actveReportStatus,
        reportParameterModal: !action.payload?.isOpen
          ? {
              rData: null,
              isOpen: false
            }
          : state?.reportParameterModal
      };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchReportData.pending, (state) => {
        return {
          ...state,
          isPending: true,
          reportData: []
        };
      })
      .addCase(fetchReportData.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          reportData: action.payload
        };
      })
      .addCase(fetchReportData.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          reportData: [],
          errorMessage: action.error.message
        };
      })
      .addCase(fetchReportCount.pending, (state) => {
        return {
          ...state,
          isLoadedTiles: true,
          reportCount: []
        };
      })
      .addCase(fetchReportCount.fulfilled, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isSuccess: true,
          reportCount: action.payload
        };
      })
      .addCase(fetchReportCount.rejected, (state, action) => {
        return {
          ...state,
          isLoadedTiles: false,
          isError: true,
          errorMessage: action.error.message,
          reportCount: []
        };
      })
      .addCase(fetchAllVendors.pending, (state) => {
        return {
          ...state,
          isPending: true,
          allVendors: []
        };
      })
      .addCase(fetchAllVendors.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          allVendors: action.payload
        };
      })
      .addCase(fetchAllVendors.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message,
          reportCount: []
        };
      })
      .addCase(saveFilterParameter.pending, (state) => {
        return {
          ...state,
          isUpdateLoad: true
        };
      })
      .addCase(saveFilterParameter.fulfilled, (state) => {
        return {
          ...state,
          isUpdateLoad: false,
          isSuccess: true
        };
      })
      .addCase(saveFilterParameter.rejected, (state, action) => {
        return {
          ...state,
          isUpdateLoad: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchFilterParamByUser.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchFilterParamByUser.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true
        };
      })
      .addCase(fetchFilterParamByUser.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchAllReportData.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchAllReportData.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          allReportDataDropDown: action.payload
        };
      })
      .addCase(fetchAllReportData.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(fetchProviderDetails.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(fetchProviderDetails.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          scheduleUserData: action?.payload?.['Fitter Provider Type'] || []
        };
      })
      .addCase(fetchProviderDetails.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = reportSlice;

export const {
  setActveReportStatus,
  setReportParameterModal,
  setScheduleReportModal,
  setGeneratedReportModal,
  setFieldData,
  setPendingState,
  setScheduleReportModalData
} = actions;

export default reducer;
