const getToken = () => {
  const userData = localStorage.getItem('E30APIToken') || null;
  return userData;
};

const setToken = (data) => {
  localStorage.setItem('E30APIToken', data);
};

const isLogin = () => {
  const data = getToken();
  return !!data; // Simplified to return boolean directly
};

const clearLocalStorage = () => {
  localStorage.removeItem('E30APIToken');
};

const getTenantId = () => {
  return localStorage.getItem('TenantID');
};

export { clearLocalStorage, getTenantId, getToken, isLogin, setToken };
