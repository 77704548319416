import apiClient from '../axios';
import { ENDPOINTS, TENANT_ID } from '../../utils/shared/constants';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getTermsAndConditions = createAsyncThunk(
  'termsAndConditions/getTermsAndConditions',
  async (emailId) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.TERMCONDITION}?email=${emailId}&tenant_id=${TENANT_ID}`
    );
    return data;
  }
);

export const postTermsAndConditions = createAsyncThunk(
  'termsAndConditions/postTermsAndConditions',
  async (data) => {
    const response = await apiClient.post(`${ENDPOINTS.POSTTERMCONDITION}`, data);
    return response.data;
  }
);
