const localhostRegExp = /^(localhost|\[::1\]|127(?:\.(?:25[0-5]|2[0-4]\d|1\d{2}|[1-9]?\d)){3})$/;

const isLocalhost = Boolean(localhostRegExp.exec(window.location.hostname));

export function register(config) {
  if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
    if (publicUrl.origin !== window.location.origin) {
      return;
    }

    window.addEventListener('load', () => {
      const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

      if (isLocalhost) {
        checkValidServiceWorker(swUrl, config);
        showLocalhostNotification();
      } else {
        registerValidSW(swUrl, config);
      }
    });
  }
}

function registerValidSW(swUrl, config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      const onStateChange = (installingWorker) => {
        if (installingWorker.state === 'installed') {
          handleInstalledState(installingWorker, registration, config);
        }
      };

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;
        if (installingWorker) {
          installingWorker.onstatechange = () => onStateChange(installingWorker);
        }
      };
    })
    .catch((error) => console.error('Error during service worker registration:', error));
}

function handleInstalledState(installingWorker, registration, config) {
  if (navigator.serviceWorker.controller) {
    console.log('New content is available. Close all tabs for updates.');
    executeCallback(config.onUpdate, registration);
  } else {
    console.log('Content is cached for offline use.');
    executeCallback(config.onSuccess, registration);
  }
}

function executeCallback(callback, param) {
  if (callback) {
    callback(param);
  }
}

function showLocalhostNotification() {
  navigator.serviceWorker.ready.then(() => {
    console.log('Serving cache-first via service worker. Learn more: https://cra.link/PWA');
  });
}

function checkValidServiceWorker(swUrl, config) {
  fetch(swUrl, { headers: { 'Service-Worker': 'script' } })
    .then((response) => {
      const contentType = response.headers.get('content-type');
      if (response.status === 404 || (contentType && contentType.indexOf('javascript') === -1)) {
        unregisterAndReload();
      } else {
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      console.log('App is running in offline mode.');
    });
}

function unregisterAndReload() {
  navigator.serviceWorker.ready.then((registration) => {
    registration.unregister().then(() => window.location.reload());
  });
}

export function unregister() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        console.error(error.message);
      });
  }
}
