import { MsalProvider } from '@azure/msal-react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './assets/scss/style.scss';
import msalInstance from './authConfig';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './store';

// Utility function to add the Clarity script dynamically
const addClarityScript = () => {
  // Check if clarity script is already added
  if (!window.clarity) {
    // Define clarity function
    window.clarity =
      window.clarity ||
      function () {
        (window.clarity.q = window.clarity.q || []).push(arguments);
      };

    // Create script element
    let script = document.createElement('script');
    script.async = 1;

    script.src = 'https://www.clarity.ms/tag/mb9m1rt3f3';

    if (process.env.REACT_APP_ENV === 'uat') {
      script.src = 'https://www.clarity.ms/tag/ldkgfe1jyh';
    }

    if (process.env.REACT_APP_ENV === 'production') {
      script.src = 'https://www.clarity.ms/tag/mxjsse427s';
    }

    // Insert script into DOM
    let firstScript = document.getElementsByTagName('script')[0];
    firstScript.parentNode.insertBefore(script, firstScript);
  }
};

// Check if environment is qa, and if so, add Clarity script
if (process.env.REACT_APP_ENV === 'uat' || process.env.REACT_APP_ENV === 'production') {
  addClarityScript();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
