import { createSlice } from '@reduxjs/toolkit';
import { getTermsAndConditions, postTermsAndConditions } from '../api/services/termsCondition.js';

const initialState = {
  isOpen: false,
  apiResponse: null,
  isPending: false,
  isError: false,
  errorMessage: null
};

const termsAndConditionsSlice = createSlice({
  name: 'termsAndConditions',
  initialState,
  reducers: {
    closeModal: (state) => {
      state.isOpen = false;
    },
    setAcceptedStatus: (state, action) => {
      state.isOpen = action.payload?.isPopUp !== false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTermsAndConditions.pending, (state) => {
        state.isPending = true;
      })
      .addCase(getTermsAndConditions.fulfilled, (state, action) => {
        state.isPending = false;
        state.apiResponse = action.payload;
        state.isOpen = true;
      })
      .addCase(getTermsAndConditions.rejected, (state, action) => {
        state.isPending = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      })
      .addCase(postTermsAndConditions.pending, (state) => {
        state.isPending = true;
      })
      .addCase(postTermsAndConditions.fulfilled, (state) => {
        state.isPending = false;
      })
      .addCase(postTermsAndConditions.rejected, (state, action) => {
        state.isPending = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  }
});

export const { closeModal, setAcceptedStatus } = termsAndConditionsSlice.actions;

export default termsAndConditionsSlice.reducer;
