import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../pages/Patients/utils';
import { ENDPOINTS as VISIT_ENDPOINTS } from '../../pages/Visits/utils';
import {
  ENDPOINTS as COMMON_ENDPOINTS,
  PATIENT_DOCUMENT_TYPE_ID,
  TENANT_ID
} from '../../utils/shared/constants';
import apiClient from '../axios';

export const addOrEditPatient = createAsyncThunk(
  'patient/addOrEditPatient',
  async ({ formattedData, mode }, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.post(ENDPOINTS.PATIENT_ADD_EDIT, formattedData);
      return { data, mode };
    } catch (error) {
      return rejectWithValue(error.response ? error.response?.data?.Messages[0] : error.message);
    }
  }
);

export const getPatient = createAsyncThunk(
  'patient/getPatient',
  async ({ patient_id, hL7, mrn = false }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.PATIENT_FETCH}?patientId=${patient_id}&tenantId=${TENANT_ID}&IsHL7=${hL7}&mrn=${mrn}`
    );
    return { data };
  }
);

export const validateMRN = createAsyncThunk('patient/validateMRN', async (payload) => {
  const account_id = payload.accountId;
  const { data } = await apiClient.get(`${ENDPOINTS.VALIDATE_MRN}?account_id=${account_id}`);
  return data;
});

export const uploadDocument = createAsyncThunk('patient/uploadDocument', async (payload) => {
  const { data } = await apiClient.post(ENDPOINTS.PATIENT_DOCUMENT_UPLOAD, payload);
  return data;
});

export const removeDocument = createAsyncThunk('patient/removeDocument', async (patientId) => {
  const data = await apiClient.delete(`${ENDPOINTS.PATIENT_DOCUMENT_REMOVE}/${patientId}`);
  return data;
});

export const sendEmail = createAsyncThunk('patient/sendEmail', async (payload) => {
  const { visitId, docId } = payload;
  const { data } = await apiClient.get(
    `${ENDPOINTS.PATIENT_SEND_EMAIL}?visitId=${visitId}&documentId=${docId}`
  );
  return data;
});

export const getState = createAsyncThunk('patient/getState', async () => {
  const { data } = await apiClient.get(`${COMMON_ENDPOINTS.FETCH_STATE}`);
  return data;
});

export const fetchAuditLogs = createAsyncThunk(
  'patient/fetchAuditLogs',
  async ({ patientId, module }) => {
    const { data } = await apiClient.get(`${ENDPOINTS.FETCH_AUDIT_LOGS}/${patientId}/${module}`);
    return data;
  }
);

export const searchPayerNameOrCode = createAsyncThunk(
  'patient/searchPayerNameOrCode',
  async ({ searchText }) => {
    const { data } = await apiClient.get(`${ENDPOINTS.PATIENT_PAYER_SEARCH}/${searchText}`);
    return { items: data, searchText: searchText };
  }
);

export const searchPayerNameCodeSettings = async (searchInput) => {
  const { data } = await apiClient.get(`${ENDPOINTS.PATIENT_PAYER_SEARCH}/${searchInput}`);
  return data;
};

export const fetchPatientCounts = createAsyncThunk('patient/fetchPatientCounts', async () => {
  const { data } = await apiClient.get(ENDPOINTS.PATIENT_COUNT);
  return data;
});

export const fetchPatientList = createAsyncThunk(
  'patient/fetchPatientList',
  async (payloadData) => {
    const { data } = await apiClient.post(ENDPOINTS.PATIENT_LIST, payloadData);
    return data;
  }
);

export const checkPatientEligibility = createAsyncThunk(
  'patient/checkPatientEligibility',
  async (patientId) => {
    const { data } = await apiClient.post(
      `${ENDPOINTS.PATIENT_ELIGIBILITY_CHECK_POST}?patientId=${patientId}`
    );
    return data;
  }
);
export const fetchPatientDocument = createAsyncThunk(
  'patient/fetchPatientDocument',
  async (patientId) => {
    const { data } = await apiClient.get(
      `${VISIT_ENDPOINTS.VISIT_VIEW_DOCUMENTS}?Patient_Id=${patientId}&Document_Type_Id=${PATIENT_DOCUMENT_TYPE_ID}`
    );
    return data;
  }
);

export const GetMigratedDocument = createAsyncThunk(
  'patient/GetMigratedDocument',
  async ({ id }) => {
    const response = await apiClient.get(`${VISIT_ENDPOINTS.GET_MIGRATED_DOCUMENT}?id=${id}`, {
      responseType: 'arraybuffer'
    });

    return response;
  }
);

export const GetViewDownloadDocument = createAsyncThunk(
  'patient/GetViewDownloadDocument',
  async ({ id }) => {
    const response = await apiClient.get(
      `${VISIT_ENDPOINTS.GET_VIEW_DOWNLOAD_DOCUMENTS}?Id=${id}`,
      {
        responseType: 'arraybuffer'
      }
    );

    return response;
  }
);

export const fetchDuplicatePatientData = async (formattedData) => {
  const { data } = await apiClient.post(ENDPOINTS.DUPLICATE_PATIENT_CHECK, formattedData);
  return data;
};

export const fetchReturnReason = createAsyncThunk('patient/fetchReturnReason', async () => {
  const { data } = await apiClient.get(ENDPOINTS.RETURN_REASON);
  return data;
});

export const fetchReturnItem = createAsyncThunk('patient/fetchReturnItem', async (payloadData) => {
  const { data } = await apiClient.post(ENDPOINTS.RETURN_ITEM, payloadData);
  return data;
});

export const fetchReturnItemToStock = createAsyncThunk(
  'patient/fetchReturnItemToStock',
  async (payloadData) => {
    const { data } = await apiClient.post(ENDPOINTS.RETURN_ITEM_STOCK, payloadData);
    return data;
  }
);
