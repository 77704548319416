import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Languages
import English from './English.json';

const resources = {
  en: {
    translation: English
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources,
    lng: localStorage.getItem('interface-language') || 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: localStorage.getItem('interface-language') || 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
      format: function (value, format) {
        //, lang
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'uppercase') return value.substr(0, 1).toUpperCase() + value.substr(1);
        return value;
      }
    }
  });

export default i18n;
