import { createSlice } from '@reduxjs/toolkit';
import {
  deletePOC,
  fetchState,
  fetchTenantInfo,
  fetchTimezone,
  updateTenantInfo,
  uploadLogoDocument
} from '../api/services/tenantInfo.service';

const initialState = {
  isPending: false,
  isSuccess: false,
  isError: false,
  errorMessage: null,
  tenantInfo: {},
  timeZoneData: [],
  stateData: [],
  logoUploadModal: false
};

const tenantInfoSlice = createSlice({
  name: 'tenatInfo',
  initialState,
  reducers: {
    setLogoUploadModal: (state, action) => {
      return { ...state, logoUploadModal: action.payload };
    }
  },
  extraReducers(builder) {
    builder
      .addCase(fetchTenantInfo.pending, (state) => {
        return {
          ...state,
          isPending: true,
          tenantInfo: {}
        };
      })
      .addCase(fetchTenantInfo.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          tenantInfo: action.payload
        };
      })
      .addCase(fetchTenantInfo.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          tenantInfo: {},
          errorMessage: action.error.message
        };
      })
      .addCase(fetchTimezone.pending, (state) => {
        return {
          ...state,
          isPending: true,
          timeZoneData: []
        };
      })
      .addCase(fetchTimezone.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          timeZoneData: action.payload
        };
      })
      .addCase(fetchTimezone.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          timeZoneData: [],
          errorMessage: action.error.message
        };
      })
      .addCase(fetchState.pending, (state) => {
        return {
          ...state,
          isPending: true,
          stateData: []
        };
      })
      .addCase(fetchState.fulfilled, (state, action) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true,
          stateData: action.payload
        };
      })
      .addCase(fetchState.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          stateData: [],
          errorMessage: action.error.message
        };
      })
      .addCase(updateTenantInfo.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(updateTenantInfo.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true
        };
      })
      .addCase(updateTenantInfo.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(deletePOC.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(deletePOC.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true
        };
      })
      .addCase(deletePOC.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      })
      .addCase(uploadLogoDocument.pending, (state) => {
        return {
          ...state,
          isPending: true
        };
      })
      .addCase(uploadLogoDocument.fulfilled, (state) => {
        return {
          ...state,
          isPending: false,
          isSuccess: true
        };
      })
      .addCase(uploadLogoDocument.rejected, (state, action) => {
        return {
          ...state,
          isPending: false,
          isError: true,
          errorMessage: action.error.message
        };
      });
  }
});

const { reducer, actions } = tenantInfoSlice;

export const { setLogoUploadModal } = actions;

export default reducer;
