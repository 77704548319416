import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../pages/Stock/utils';
import apiClient from '../axios';

export const getStockByFilter = createAsyncThunk('stock/getStockByFilter', async (payload) => {
  const { data } = await apiClient.post(ENDPOINTS.GET_STOCK, payload);
  return { data };
});

export const getStockTiles = createAsyncThunk('stock/getStockTiles', async () => {
  const { data } = await apiClient.get(ENDPOINTS.GET_STOCK_TILES);
  return { data };
});

export const GetStocksByLocation = createAsyncThunk(
  'stock/GetStocksByLocation',
  async ({ Location_id }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.GET_STOCKS_BY_LOCATION}?Location_id=${Location_id}`
    );
    return data;
  }
);

export const GetStocksDetails = createAsyncThunk(
  'stock/GetStocksDetails',
  async ({ itemId, sourceLocationId, destinationLocationId }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.GET_STOCKS_DETAILS}?itemId=${itemId}&sourceLocationId=${sourceLocationId}&destinationLocationId=${destinationLocationId}`
    );
    return data;
  }
);

export const GetSourceLocationDropDown = createAsyncThunk(
  'stock/GetSourceLocationDropDown',
  async ({ Item_Id }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.GET_SOURCE_LOCATION_DROPDOWN}?Item_Id=${Item_Id}`
    );
    return data;
  }
);

export const UpdateStock = createAsyncThunk('stock/UpdateStock', async (payload) => {
  const { data } = await apiClient.post(ENDPOINTS.UPDATE_STOCK, payload);
  return data;
});

export const EditStockDetails = createAsyncThunk('stock/EditStockDetails', async (payload) => {
  const { data } = await apiClient.post(ENDPOINTS.EDIT_STOCK_DETAILS, payload);
  return { data };
});

export const GetItemsByFilter = createAsyncThunk('stock/GetItemsByFilter', async (payload) => {
  const { data } = await apiClient.post(ENDPOINTS.GET_ITEMS_BY_FILTER, payload);
  return data;
});

export const AddItemsToStock = createAsyncThunk('stock/AddItemsToStock', async (payload) => {
  const { data } = await apiClient.post(ENDPOINTS.ADD_ITEMS_TO_STOCK, payload);
  return data;
});

export const UpdateStockTransfer = createAsyncThunk(
  'stock/UpdateStockTransfer',
  async (payload) => {
    const { data } = await apiClient.post(ENDPOINTS.UPDATE_STOCK_TRANSFER, payload);
    return data;
  }
);

export const GetCountedDaysByLocation = createAsyncThunk(
  'stock/GetCountedDaysLocations',
  async (payload) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.COUNTED_DAYS_BY_LOCATION}?Location_id=${payload}`
    );
    return data;
  }
);
