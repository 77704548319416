import { t } from 'i18next';
import React from 'react';
import { ReactComponent as AccessDeniedIcon } from '../../assets/images/access-denied.svg';
import '../ErrorPage/ErrorPage.scss';

const Error403 = () => {
  return (
    <div className="error-parent">
      <div className="error-content">
        <AccessDeniedIcon className="error-image" data-testid="access-denied" />
        <h1>{t('unAuthorized.title')}</h1>
        <p>{t('unAuthorized.message1')}</p>
      </div>
    </div>
  );
};

export default Error403;
