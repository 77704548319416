import axios from 'axios';
import msalInstance from '../authConfig';
import { getClientIP } from '../utils/shared/common';
import {
  AXIOS_BASE_CONFIG,
  LOGIN_REDIRECT_SCOPE,
  LOGIN_REDIRECT_SCOPE_PROD
} from '../utils/shared/constants';
import { getTenantId, getToken, isLogin, setToken } from './auth';

const createAxiosInstance = () => {
  const instance = axios.create(AXIOS_BASE_CONFIG);

  instance.interceptors.request.use(async (config) => {
    const accessToken = getToken();
    const isLoggedIn = isLogin();
    const tenantId = getTenantId();

    config.headers.tid = tenantId;
    config.headers.ipAddress = await getClientIP();

    if (isLoggedIn) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });

  instance.interceptors.response.use((response) => response, handleErrorResponse);

  return instance;
};

const handleErrorResponse = async (error) => {
  if (error.response) {
    const { status, config } = error.response;

    if (status === 409) {
      handleLogoutRedirect(error.response);
      return;
    }

    if (status === 403) {
      // Handle general 403 unauthorized case
      if (window.location.pathname !== '/unauthorized') {
        window.location.href = '/unauthorized';
      }
      return;
    }

    if (status === 401 && !config.__isRetryRequest) {
      config.__isRetryRequest = true;

      const tenantId = getTenantId();

      const newAccessToken = await refreshToken();
      setToken(newAccessToken);

      config.headers.Authorization = `Bearer ${newAccessToken}`;
      config.headers.tid = tenantId;
      config.headers.ipAddress = await getClientIP();

      return axios(config);
    } else {
      handleOtherErrors(error.response);
    }
  } else if (error.request) {
    handleNetworkError();
  } else {
    handleRequestSetupError();
  }

  return Promise.reject(error);
};

const handleLogoutRedirect = async (response) => {
  console.error('handleLogoutRedirect Error:', response);
  await msalInstance.logoutRedirect();
};

const handleOtherErrors = (response) => {
  console.error('Other Error:', response);
  // Handle other error status codes
};

const handleNetworkError = () => {
  // Handle network errors here
  console.error('Network Error:');
};

const handleRequestSetupError = () => {
  console.error('Error in setting up the request');
};

// Function to refresh token using the hook
const refreshToken = async () => {
  const accounts = msalInstance.getAllAccounts();

  if (accounts && accounts.length > 0) {
    const request = {
      scopes:
        process.env.REACT_APP_ENV === 'production'
          ? LOGIN_REDIRECT_SCOPE_PROD
          : LOGIN_REDIRECT_SCOPE,
      account: accounts[0]
    };

    try {
      const response = await msalInstance.acquireTokenSilent(request);
      return response.accessToken;
    } catch (error) {
      // Handle the error (e.g., token acquisition failure)
      console.error('Token acquisition failed:', error);
      handleLogoutRedirect(error.response);
      return;
    }
  } else {
    console.error('No Account Found for refresh Token');
    handleLogoutRedirect('No Account Found for refresh Token');
  }
};

const axiosInstance = createAxiosInstance();

export default axiosInstance;
