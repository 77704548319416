import { PublicClientApplication } from '@azure/msal-browser';

export const b2cPolicies = {
  names: {
    signIn: 'b2c_1_signIn'
  },
  authorities: {
    signIn: {
      authority: process.env.REACT_APP_AUTHORITY
    }
  },
  authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN
};

export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: b2cPolicies.authorities.signIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_REDIRECT_URI
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true
  }
};

const msalInstance = new PublicClientApplication(msalConfig);

// Make the MSAL instance available for use in your application
export default msalInstance;
