/**
 * Typography used in theme
 * @param {JsonObject} theme theme customization object
 */

export default function themeTypography() {
  return {
    fontFamily: ['DM Sans'].join(','),
    color: '#505050',
    fontSize: 14,
    lineHeight: '1.125rem',
    fontWeight: '400',

    h1: {
      fontSize: 20,
      lineHeight: '2rem',
      fontWeight: '500'
    },
    h2: {
      fontSize: 14,
      lineHeight: '1.25rem',
      fontWeight: '500'
    },
    h3: {
      fontSize: 12,
      lineHeight: '1.125rem',
      fontWeight: '500'
    },
    body1: {
      // fontSize: '1rem',
      lineHeight: '1.125rem',
      fontWeight: 'normal'
      // color: '#696969'
    },
    body2: {
      fontSize: 14,
      lineHeight: '1.125rem',
      fontWeight: '500',
      color: '#A3A3A3'
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: '1rem',
      fontWeight: '400',
      color: '#A9A6A6'
    },
    subtitle2: {
      fontSize: 13.008,
      lineHeight: '1.125rem',
      fontWeight: '400',
      color: '#FF6D6D'
    },
    button: {
      textTransform: 'capitalize',
      fontSize: 13.008,
      lineHeight: '1.125rem',
      fontWeight: '400'
    }
  };
}
