import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { getClientIP } from '../../utils/shared/common';
import { baseURL, ENDPOINTS } from '../../utils/shared/constants';
import apiClient from '../axios';

export const validateEmail = async (emailId) => {
  const ipAddress = await getClientIP();
  const response = await axios.get(`${baseURL}${ENDPOINTS.VALIDATE_USER}${emailId}`, {
    headers: {
      ipAddress: ipAddress
    }
  });
  return response.data;
};

// Tenant and Location
export const fetchUserTenant = createAsyncThunk('main/fetchUserTenant', async () => {
  const { data } = await apiClient.get(ENDPOINTS.USER_TENANT);
  return data;
});

export const updateUserLogoutTime = createAsyncThunk(
  'main/updateUserLogoutTime',
  async ({ userId, tenantId }) => {
    const { data } = await apiClient.get(
      `${ENDPOINTS.UPDATE_USER_LOGOUT_TIME}?userId=${userId}&tenantId=${tenantId}`
    );
    return data;
  }
);

export const updateUserTenant = createAsyncThunk(
  'main/updateUserTenant',
  async (apiUserTenantData) => {
    const { data } = await apiClient.post(ENDPOINTS.USER_TENANT_UPDATE, apiUserTenantData);
    return data;
  }
);

// not in use
export const fetchDashboardPatientTiles = createAsyncThunk(
  'main/fetchDashboardPatientTiles',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.DASHBOARD_PATIENT_TILES);
    return data;
  }
);

export const fetchDashboardVisitTiles = createAsyncThunk(
  'main/fetchDashboardVisitTiles',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.DASHBOARD_VISIT_TILES);
    return data;
  }
);

export const fetchDashboardCompletedVisitTiles = createAsyncThunk(
  'main/fetchDashboardCompletedVisitTiles',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.DASHBOARD_COMPLETED_VISIT_TILES);
    return data;
  }
);

export const fetchDashboardItemsToOrderTiles = createAsyncThunk(
  'main/fetchDashboardItemsToOrderTiles',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.DASHBOARD_ITEMS_TO_ORDER_TILES);
    return data;
  }
);

export const fetchDashboardPurchaseTiles = createAsyncThunk(
  'main/fetchDashboardPurchaseTiles',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.DASHBOARD_PURCHASE_TILES);
    return data;
  }
);

export const fetchDashboardStockTiles = createAsyncThunk(
  'main/fetchDashboardStockTiles',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.DASHBOARD_STOCK_TILES);
    return data;
  }
);

// not in use
export const fetchDashboardCatalogTiles = createAsyncThunk(
  'main/fetchDashboardCatalogTiles',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.DASHBOARD_CATALOG_TILES);
    return data;
  }
);

export const fetchUserTenantLocation = createAsyncThunk(
  'main/fetchUserTenantLocation',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.USER_TENANT_LOCATION);
    return data;
  }
);
