import React from 'react';
import { ToastContainer as ReactToastifyContainer } from 'react-toastify';

const ToastContainer = () => {
  return (
    <ReactToastifyContainer
      position="top-center"
      autoClose={3000}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};

export default ToastContainer;
