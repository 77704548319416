import moment from 'moment/moment';

export const patientTabs = ['Personal Details', ' Contact Info', 'Payer Details', 'Documents'];
export const receiveOrderTabs = ['Receive PO Items', 'PO Transaction Logs'];
export const patientSummaryTabs = [
  'Personal Details',
  'Payers Details',
  'Previous Visits',
  'Eligibility',
  'Documents',
  'Audit Logs'
];

export const allowedFileTypes = [
  'image/gif',
  'image/jpeg',
  'image/jpg',
  'image/png',
  'application/pdf'
];
export const allowedDocumentFileTypes = ['image/jpeg', 'image/png', 'application/pdf'];

export const allowedImageFileTypes = ['image/jpeg', 'image/png'];

export const styles = {
  button: {
    textTransform: 'none'
  },
  select: {
    padding: '1.5px 14px' // Apply custom padding to Select
  },
  container: {
    maxWidth: '400px',
    margin: '0 auto',
    padding: '20px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    backgroundColor: '#f0f0f0'
  },

  inputContainer: {
    marginBottom: '20px'
  },

  dropzoneContainer: {
    border: '2px dashed #ccc',
    padding: '20px',
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '20px',
    backgroundColor: '#f4f6fa',
    borderRadius: '5px',
    marginTop: '10px'
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '20px'
  },

  uploadButton: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px 20px',
    border: 'none',
    cursor: 'pointer'
  },
  uploadButtonHover: {
    backgroundColor: '#0056b3'
  }
};

export const unformatPhoneNumber = (formattedNumber) => {
  return formattedNumber?.replace(/[^\d]/g, '');
};

export const formatMobileNumber = (mobileNumber) => {
  if (mobileNumber) {
    const numericOnly = mobileNumber.replace(/\D/g, '');
    const formattedNumber = numericOnly.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    return formattedNumber;
  }
  return '';
};

export const convertDateToUTC = (date, timeZone) => {
  if (!date || !timeZone) {
    return null; // Return null if necessary information is missing
  }
  const dateformatMMDDYYYY = 'MM/DD/YYYY';

  // Convert string to Date object
  const dateNew = new Date(date);

  // Extract year, month, and day
  const year = dateNew.getFullYear();
  const month = (dateNew.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 as month starts from 0
  const day = dateNew.getDate().toString().padStart(2, '0');

  // MM/DD/YYYY format
  const dateString = month + '/' + day + '/' + year;

  const localDate = moment.tz(`${dateString} 00:00:00`, `${dateformatMMDDYYYY} HH:mm:ss`, timeZone);

  const utcDate = localDate.utc().format();

  return utcDate;
};

export const formatDateToCustomISO = (date) => {
  if (!date) {
    return null; // Return null if the date is missing
  }

  const dateNew = new Date(date);

  const year = dateNew.getFullYear();
  const month = (dateNew.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 as month starts from 0
  const day = dateNew.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}T00:00:00Z`;

  return formattedDate;
};

export const updatedFormattedDate = (date) => {
  if (!date) {
    return null; // Return null if the date is missing
  }

  const dateNew = new Date(date);

  const year = dateNew.getFullYear();
  const month = (dateNew.getMonth() + 1).toString().padStart(2, '0'); // Adding 1 as month starts from 0
  const day = dateNew.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}-${month}-${day}`;

  return formattedDate;
};

export const createUpdatePatientData = (
  fetchedPatientData,
  formValues,
  isUpdate,
  selectedLocation
) => {
  const commonPayload = {
    tenant_Location_Id: selectedLocation,
    external_System_Id: '72F81FE6-33E1-4633-93B1-F2D4AF6BA2ED',
    first_Name: formValues?.firstName?.trim(),
    last_Name: formValues?.lastName?.trim(),
    gender: formValues?.gender,
    date_Of_Birth: updatedFormattedDate(formValues?.dob),
    account_Id: formValues?.MRN,
    address_Line1: formValues?.addressLine1,
    address_Line2: formValues?.addressLine2,
    city: formValues?.city,
    state_Province: formValues?.state,
    zip_Code: formValues?.zipCode,
    primary_Phone: unformatPhoneNumber(formValues?.mobileNo),
    secondary_Phone: unformatPhoneNumber(formValues?.phoneNo),
    primary_Email: formValues?.email,
    patientPayerDetails: formValues?.payerDetails
      ?.filter((p) => (p.isDeletable && p.payer_id) || !p.isDeletable)
      .map((field) => ({
        ...(isUpdate && {
          id: field?.payer_id,
          is_Deleted: field?.isDeletable ? 1 : 0
        }),
        tenant_Payer_Id: field?.payernameandcode?.id,
        payer_Level: field?.payerlevel,
        subscriber_Member_Id: field?.subMemberID,
        group_Number: field?.group,
        subscriber_First_Name: field?.subFirstName,
        subscriber_Last_Name: field?.subLastName,
        subscriber_Dob: updatedFormattedDate(field?.subDOB),
        subscriber_Relationship_To_Patient: field?.SubRelationship
      }))
  };

  return isUpdate
    ? {
        ...commonPayload,
        id: fetchedPatientData.id
      }
    : { ...commonPayload };
};

export const ENDPOINTS = {
  // Patients
  PATIENT_ADD_EDIT: 'patientservice/api/Patient/AddOrEditPatient',
  PATIENT_FETCH: 'patientservice/api/Patient/GetPatientById',
  VALIDATE_MRN: 'patientservice/api/Patient/GetPatientAccountValidate',
  PATIENT_DOCUMENT_UPLOAD: 'patientservice/api/DocumentUpload/UploadFiles',
  PATIENT_DOCUMENT_REMOVE: 'patientservice/api/DocumentUpload/DeleteUploadedFile',
  PATIENT_SEND_EMAIL: 'patientservice/api/SendEmail/SendEmail',
  FETCH_AUDIT_LOGS: 'userservice/api/Auditlogs/getauditlogs',
  PATIENT_PAYER_SEARCH: 'patientservice/api/TenantPayer/searchTenantPayer',
  PATIENT_COUNT: 'patientservice/api/Patient/PatientTilesDetail',
  PATIENT_LIST: 'patientservice/api/Patient/patients',
  PATIENT_ELIGIBILITY_CHECK_POST: 'patientservice/api/PverifyGeneric/PatientEligibilityCheck',
  DUPLICATE_PATIENT_CHECK: 'patientservice/api/Patient/DuplicatePatientCheck',
  RETURN_REASON: 'patientservice/api/visit/getReturnReasons',
  RETURN_ITEM: 'patientservice/api/visit/getItemsListToReturn',
  RETURN_ITEM_STOCK: '/patientservice/api/visit/returnItemsToStock'
};
