import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../utils/shared/constants';
import apiClient from '../axios';

export const fetchTenantInfo = createAsyncThunk('setting/fetchTenantInfo', async () => {
  const { data } = await apiClient.get(ENDPOINTS.TENANT_INFO_FETCH);
  return data;
});

export const fetchTimezone = createAsyncThunk('setting/fetchTimezone', async () => {
  const { data } = await apiClient.get(ENDPOINTS.TIMEZONE_FETCH);
  return data;
});

export const fetchState = createAsyncThunk('setting/fetchState', async () => {
  const { data } = await apiClient.get(ENDPOINTS.FETCH_STATE);
  return data;
});

export const updateTenantInfo = createAsyncThunk(
  'setting/updateTenantInfo',
  async (payloadData) => {
    const { data } = await apiClient.put(ENDPOINTS.UPDATE_TENANT_INFO, payloadData);
    return data;
  }
);

export const deletePOC = createAsyncThunk('patient/deletePOC', async (pocId) => {
  const data = await apiClient.delete(`${ENDPOINTS.DELETE_POC}`, { data: [pocId] });
  return data;
});

export const uploadLogoDocument = createAsyncThunk(
  'patient/uploadLogoDocument',
  async (payload) => {
    const { data } = await apiClient.post(ENDPOINTS.TENANT_LOGO_UPLOAD, payload);
    return data;
  }
);
