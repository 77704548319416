// utils.js
import { t } from 'i18next';
import { isEmpty } from 'lodash';
import { createBundles } from '../../api/services/catalog.service';

const getHcpcsValue = (data) => {
  if (!isEmpty(data)) {
    return {
      id: data?.id,
      hcspcs: data?.hcpcs,
      description: data?.description
    };
  }
  return null;
};

const getManufacturerValue = (data) => {
  if (!isEmpty(data)) {
    return {
      description: data,
      id: data
    };
  }
  return null;
};

const getRecommendedReplacement = (data) => {
  if (!isEmpty(data)) {
    return {
      id: data?.id,
      hcspcs: data?.hcpcs,
      description: data?.description
    };
  }
  return null;
};

export const setCatalogFormValue = (setValue, tenantItem) => {
  return (
    setValue('itemName', tenantItem.itemName),
    setValue('itemNumber', tenantItem.itemNumber),
    setValue('manufacturer_SKU', tenantItem?.manufacturer_SKU),
    setValue('manufacturer', getManufacturerValue(tenantItem.manufacturer)),
    setValue('mfg_desc', tenantItem.mfg_desc),
    setValue('size', tenantItem.size),
    setValue('laterality', tenantItem.laterality),
    setValue('barcode1', tenantItem.barcode1),
    setValue('barcode2', tenantItem.barcode2),
    setValue('ndc_number', tenantItem.ndc_number),
    setValue('is_lot_number_required', tenantItem.is_lot_number_required),
    setValue('manufacturer_product_info', tenantItem.manufacturer_product_info),
    setValue('qr_code_url', tenantItem.qr_code_url),
    setValue('link_text', tenantItem.link_text),
    setValue('item_instructions_notes', tenantItem.item_instructions_notes),
    setValue('send_DFT', tenantItem.send_DFT),
    setValue('custom_fit_hcpcs', getHcpcsValue(tenantItem?.custom_fit_hcpcs)),
    setValue('bill_type_id', tenantItem.bill_type_id),
    setValue('is_nu', tenantItem.is_nu),
    setValue('is_kx', tenantItem.is_kx),
    setValue(
      'recommended_replacement_item',
      getRecommendedReplacement(tenantItem.recommendedReplacement_Item)
    ),
    setValue('qr_code_storage_key', tenantItem.qr_code_storage_key),
    setValue('hcpcs', getHcpcsValue(tenantItem?.hcpcs))
  );
};

export const DEFAULT_BILL_TYPE = 'cafb9461-90df-43b3-90a8-45f7befbb952';

export const submitBundleForm = async ({
  catalogItemData,
  bundleItemData,
  bundleData,
  data,
  isActive,
  isBundleEditing,
  dispatch,
  onCloseDialog,
  toastError,
  toastSuccess
}) => {
  let submissionData = {};
  const processedCatalogItems = catalogItemData?.map((item) => ({
    ...item
  }));

  if (isBundleEditing) {
    let processedItems = bundleItemData?.map((item) => ({
      ...item,
      is_deleted: true // Initially mark all as deleted
    }));

    processedCatalogItems?.forEach((catalogItem) => {
      let existingItem = processedItems?.find((item) => item?.id === catalogItem?.id);
      if (existingItem) {
        Object.assign(existingItem, catalogItem, { is_deleted: false });
      } else {
        processedItems.push({ ...catalogItem, is_deleted: false });
      }
    });

    submissionData = {
      tenantDevices: {
        id: bundleData?.id, // Set the id from bundleData when editing
        bundle_Name: data?.bundleName,
        bundle_Hcpcs: data?.bundlehcpcs?.hcspcs || null,
        bundle_Hcpcs_Id: data?.bundlehcpcs?.id || null,
        description: data?.bundledescription,
        is_Active: isActive,
        is_deleted: false
      },
      tenantDevices_Item: processedItems
    };
  } else {
    submissionData = {
      tenantDevices: {
        bundle_Name: data?.bundleName,
        bundle_Hcpcs: data?.bundlehcpcs?.hcspcs || null,
        bundle_Hcpcs_Id: data?.bundlehcpcs?.id || null,
        description: data?.bundledescription,
        is_Active: isActive,
        is_deleted: false
      },
      tenantDevices_Item: processedCatalogItems
    };
  }

  dispatch(createBundles(submissionData))
    .unwrap()
    .then(() => {
      if (isBundleEditing) {
        toastSuccess(
          t('toaster.catalog.bundles.updateBundleSuccess', { bundleName: data?.bundleName }),
          {
            toastId: 'updateBundleSuccess'
          }
        );
      } else {
        toastSuccess(
          t('toaster.catalog.bundles.addBundlesSuccess', { bundleName: data?.bundleName }),
          {
            toastId: 'addBundleSuccess'
          }
        );
      }
      onCloseDialog(); // Close the dialog on success
    })
    .catch((error) => {
      toastError(error?.message, {
        toastId: 'addBundleError'
      });
    });
};

export const getHcpcsDisplay = (item_Hcpcs, item_Custom_Hcpcs) => {
  if (item_Hcpcs && item_Custom_Hcpcs) {
    return `${item_Hcpcs}/${item_Custom_Hcpcs}`;
  }
  if (item_Hcpcs) {
    return `${item_Hcpcs}/`;
  }
  if (item_Custom_Hcpcs) {
    return `/${item_Custom_Hcpcs}`;
  }
  return ''; // Return an empty string if neither is present
};

export const ENDPOINTS = {
  // CATALOG
  SAVE_CATALOG_ITEM: 'inventoryservice/api/CatalogManagement/AddCatalog',
  GET_PURCHASE_UOM: 'userservice/api/Hcpcs/GetSearchPurchaseUom',
  SEARCH_CATALOG_VENDORS: 'inventoryservice/api/CatalogManagement/Vendors',
  SEARCH_CATALOG_PRODUCT: 'userservice/api/Hcpcs/GetSearchProduct',
  SEARCH_CATALOG_CUSTOM_HCPCS: 'userservice/api/Hcpcs/GetAllHcpcs',
  GET_SINGLE_CATALOG_ITEM: 'inventoryservice/api/CatalogManagement/GetTenantItem',
  CATALOG_COUNT: '/inventoryservice/api/CatalogManagement/CatalogManagementCount',
  CATALOG_LIST: 'inventoryservice/api/CatalogManagement/Catalog',
  CATALOG_ITEM_AUDIT_LOGS: 'inventoryservice/api/CatalogManagement/GetItemAudit',
  GET_CATALOG_MANUFACTURER_LIST:
    'userservice/api/admin/Manufacturer/GetAllManufacturersBasedOnSearch',
  GET_CATALOG_ITEM_SIZES: 'UserService/api/admin/ItemSize/GetAllItemSizes',
  GET_ALL_CATALOG_VENDORS: 'inventoryservice/api/CatalogManagement/ListOfVendors',
  CATLOG_SINGLE_BUNDLE_DATA: 'inventoryservice/api/inventory/GetBundleById',
  VALIDATE_ITEM_NUMBER: 'inventoryservice/api/CatalogManagement/GeItemNumberValidation',
  ADD_UPDATE_BUNDLE: 'inventoryservice/api/inventory/CreateBundles'
};
