import { createAsyncThunk } from '@reduxjs/toolkit';
import { ENDPOINTS } from '../../utils/shared/constants';
import { getTenantId } from '../auth';
import apiClient from '../axios';

export const fetchItemBillingTypes = createAsyncThunk(
  'visitMaster/fetchItemBillingTypes',
  async () => {
    const { data } = await apiClient.get(ENDPOINTS.VISIT_MASTER_FETCH_BILLING_TYPES);
    return data;
  }
);

export const fetchProviderDetails = createAsyncThunk(
  'visitMaster/fetchProviderDetails',
  async () => {
    const tenantId = getTenantId();
    const response = await apiClient.get(
      `${ENDPOINTS.VISIT_MASTER_PROVIDER_DETAILS}?tenant_Id=${tenantId}`
    );

    if (response?.statusText === 'OK' || response?.status === 200) {
      return response?.data;
    } else {
      return null;
    }
  }
);

export const fetchStatusDetails = createAsyncThunk('visitMaster/fetchStatusDetails', async () => {
  const response = await apiClient.get(ENDPOINTS.VISIT_MASTER_VISIT_STATUS);
  if (response.statusText === 'OK' || response.status === 200) {
    return response;
  } else {
    return null;
  }
});

export const fetchAuthStatusDetails = createAsyncThunk(
  'visitMaster/fetchAuthStatusDetails',
  async () => {
    const response = await apiClient.get(ENDPOINTS.VISIT_MASTER_AUTH_STATUS);
    if (response.statusText === 'OK' || response.status === 200) {
      return response;
    } else {
      return null;
    }
  }
);
